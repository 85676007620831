import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "details__container"
  }, [_c("page-header", {
    attrs: {
      "title": `${_vm.entity.custName} - ${_vm.entity.order}`,
      "back": _vm.onBack
    }
  }), _c("div", {
    staticClass: "details__content p-5"
  }, [_c("a-card", {
    staticClass: "mb-4"
  }, [_c("h3", {
    staticClass: "h5 neutral-10--text"
  }, [_vm._v("General Information")]), _c("a-row", {
    attrs: {
      "gutter": [24, 16]
    }
  }, [_c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Whs",
      "value": _vm.entity.whs,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Dvsn",
      "value": _vm.entity.dvsn,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Chnl",
      "value": _vm.entity.chnl,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Cust Name",
      "value": _vm.entity.custName,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Order",
      "value": _vm.entity.order,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Brnd",
      "value": _vm.entity.brand,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Cust PO",
      "value": _vm.entity.custPO,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Load No",
      "value": _vm.entity.loadNo,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Qty",
      "value": _vm.entity.qty,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Invoice Date",
      "value": _vm.entity.invoiceDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Vessel Cutoff Date",
      "value": _vm.entity.vesselCutoffDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Date Docs Due",
      "value": _vm.entity.dateDocsDue,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Met / Not Met",
      "value": _vm.entity.met,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Delayed Orders",
      "value": _vm.entity.delayedOrders,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Lead Time",
      "value": _vm.entity.leadTime,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Category",
      "value": _vm.entity.category,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Stat",
      "value": _vm.entity.stat,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Stat Descr",
      "value": _vm.entity.statDescr,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Owner",
      "value": _vm.entity.owner,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Tracking Status",
      "value": _vm.entity.trackingStatus,
      "disabled": ""
    }
  })], 1)], 1), _c("a-row", {
    attrs: {
      "gutter": [24, 16]
    }
  }, [_c("a-col", {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c("text-area", {
    staticClass: "w-100",
    attrs: {
      "label": "Notes",
      "value": _vm.entity.notes,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c("text-area", {
    staticClass: "w-100",
    attrs: {
      "label": "GBS Note",
      "value": _vm.entity.gbs,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c("a-card", {
    staticClass: "mb-4"
  }, [_c("h3", {
    staticClass: "h5 neutral-10--text"
  }, [_vm._v("Booking Information")]), _c("a-row", {
    attrs: {
      "gutter": [24, 16]
    }
  }, [_c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Booking No",
      "value": _vm.entity.bookingNo,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Pro-Forma Requested Date",
      "value": _vm.entity.proFormaSharedDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Docs Shared Date",
      "value": _vm.entity.docsSharedDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Orig ETD",
      "value": _vm.entity.origETD,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "ETD Date",
      "value": _vm.entity.etdDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "ETA Date",
      "value": _vm.entity.etaDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "PCR No",
      "value": _vm.entity.pcrNo,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c("a-card", {
    staticClass: "mb-4"
  }, [_c("h3", {
    staticClass: "h5 neutral-10--text"
  }, [_vm._v("Other Information")]), _c("a-row", {
    attrs: {
      "gutter": [24, 16]
    }
  }, [_c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "FCLLCL",
      "value": _vm.entity.fcllcl,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Value Est",
      "value": _vm.entity.valueEst,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "ETD Wk",
      "value": _vm.entity.etdWk,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Entry Date",
      "value": _vm.entity.entryDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Curr Mnth",
      "value": _vm.entity.currentMonth,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Invoice No",
      "value": _vm.entity.invoiceNo,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Inv Val",
      "value": _vm.entity.invVal,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Vessel Voyage",
      "value": _vm.entity.vesselVoyage,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "General Desc 1",
      "value": _vm.entity.generalDesc1,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "General Desc 2",
      "value": _vm.entity.generalDesc2,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "General Desc 3",
      "value": _vm.entity.generalDesc3,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "General Desc 4",
      "value": _vm.entity.generalDesc4,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "General Desc 5",
      "value": _vm.entity.generalDesc5,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Shipping Marks 1",
      "value": _vm.entity.shippingMarks1,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Shipping Marks 2",
      "value": _vm.entity.shippingMarks2,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Shipping Marks 3",
      "value": _vm.entity.shippingMarks3,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Shipping Marks 4",
      "value": _vm.entity.shippingMarks4,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Shipping Marks 5",
      "value": _vm.entity.shippingMarks5,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c("a-card", {
    staticClass: "mb-4"
  }, [_c("h3", {
    staticClass: "h5 neutral-10--text"
  }, [_vm._v("Shipment Information")]), _c("a-row", {
    attrs: {
      "gutter": [24, 16]
    }
  }, [_c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Freight Terms",
      "value": _vm.entity.freightTerms,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Inv/Ship Date",
      "value": _vm.entity.invShipDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("date-picker", {
    staticClass: "w-100",
    attrs: {
      "label": "Pick Date",
      "value": _vm.entity.pickDate,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Final Destination Port",
      "value": _vm.entity.finalDestinationPort,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Sold To Customer",
      "value": _vm.entity.soldToCustomer,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Ship To Customer",
      "value": _vm.entity.shipToCustomer,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Ship To Number",
      "value": _vm.entity.shipToNumber,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Carrier",
      "value": _vm.entity.carrier,
      "disabled": ""
    }
  })], 1), _c("a-col", {
    attrs: {
      "xs": 12,
      "md": 8,
      "lg": 6
    }
  }, [_c("text-input", {
    staticClass: "w-100",
    attrs: {
      "label": "Load Number",
      "value": _vm.entity.loadNumber,
      "disabled": ""
    }
  })], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "DetailsExportOrdersData",
  inject: ["crud", "apiUrl"],
  data() {
    const { id } = this.$route.params;
    const { typeData, countryCode } = this.$route.query;
    const customApiUrl = `${this.apiUrl}/export-order/export-order-data/${id}?countryCode=${countryCode}&typeData=${typeData}`;
    return {
      customApiUrl,
      entity: {}
    };
  },
  computed: {},
  mounted() {
    this.getDetail();
  },
  methods: {
    onBack() {
      this.$router.push({
        name: "ListExportOrdersData"
      });
    },
    async getDetail() {
      const { data } = await this.axios.get(`${this.customApiUrl}`);
      this.entity = data;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var DetailsExportOrdersData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "details-export-orders-data"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.export-order-data",
      "api-url": _vm.apiUrl,
      "resource-id-name": "order"
    }
  }, [_c("details-export-orders-data")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "DetailsExportOrdersDataIndex",
  components: {
    DetailsExportOrdersData
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "export-orders-data",
          title: "Export Orders Data",
          path: "/export-orders/export-orders-data"
        },
        {
          key: "export-orders-data",
          title: "Export Orders Data Details",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
